<template>
    <div class="card card-secondary card-outline">
        <div class="card-header">
            <div class="card-title">
                <h4><i class="fa fa-briefcase"></i> Allgemeine Einstellungen</h4>
            </div>

            <div class="card-tools">
                <b-button v-if="!editMode" size="sm" variant="primary" @click="editModeOn">Bearbeiten</b-button>
                <b-button v-if="editMode" size="sm" variant="default" @click="editModeOff" class="mr-1">Abbrechen</b-button>
                <b-button v-if="editMode" size="sm" variant="success" @click="editLabel">Speichern</b-button>
            </div>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-md-12">

                    <div class="row">

                        <div class="col-md-3">
                        <div class="form-group">
                            <label for="name" class="control-label">Name*</label>
                            <input v-model="form.name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}" :disabled="!editMode">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        </div>

                        <div class="col-md-3">
                        <div class="form-group">
                            <label for="distributor" class="control-label">Druckerei*</label>
                            <input v-model="form.distributor" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('distributor')}" :disabled="!editMode">
                            <has-error :form="form" field="distributor"></has-error>
                        </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="unit" class="control-label">Bestelleinheit*</label>
                                <select v-model="form.unit" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('unit')}" :disabled="!editMode">
                                    <option value="Stk">Stück</option>
                                    <option value="Rolle">Rolle</option>
                                </select>
                                <has-error :form="form" field="unit"></has-error>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="unit" class="control-label">Typ*</label>
                                <select v-model="form.type" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('type')}" :disabled="!editMode">
                                    <option value="product">Produktetikett</option>
                                    <option value="flyer">Flyer</option>
                                    <option value="other">Sonstiges</option>
                                </select>
                                <has-error :form="form" field="unit"></has-error>
                            </div>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="logo" class="control-label">Vorschaubild (Druckdatei)</label>
                            <b-form-file id="logo" size="sm" v-model="form.logo" :class="{'is-invalid': form.errors.has('logo')}" :disabled="!editMode"/>
                            <has-error :form="form" field="logo"></has-error>
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="pdf" class="control-label">Druckdatei</label>
                            <b-form-file id="pdf" size="sm" v-model="form.pdf" :class="{'is-invalid': form.errors.has('pdf')}" :disabled="!editMode"/>
                            <has-error :form="form" field="pdf"></has-error>
                        </div>
                        </div>
                    </div>

                    

                    <div class="row">

                        <div class="col-md-8">
                        <div class="form-group">
                            <label for="description" class="control-label">Beschreibung*</label>
                            <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" :disabled="!editMode"></ckeditor>
                            <has-error :form="form" field="description"></has-error>
                        </div>
                        </div>

                        <div class="col-md-4" v-if="label.thumbnail != ''" style="text-align: center;">
                            <figure class="float-right mt-2" style="width: 100%; height: auto;">
                                <img v-bind:src="label.thumbnail" id="brandLogo" class="img-fluid" alt="img">
                            </figure>
                        </div>

                    </div>

                    <div class="row">

                        <div class="col-md-3">
                        <div class="form-group">
                            <label for="distributor" class="control-label">&empty;-EK-Netto(€) / Label</label>
                            <input type="text" :value="label.price * 1" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('distributor')}" :disabled=true>
                        </div>
                        </div>

                        <div class="col-md-3">
                        <div class="form-group">
                            <label for="distributor" class="control-label">&empty;-EK-Netto(€) / 250 Label</label>
                            <input type="text" :value="label.price * 250" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('distributor')}" :disabled=true>
                        </div>
                        </div>


                        <div class="col-md-3">
                        <div class="form-group">
                            <label for="distributor" class="control-label">&empty;-EK-Netto(€) / 1000 Label</label>
                            <input type="text" :value="label.price * 1000" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('distributor')}" :disabled=true>
                        </div>
                        </div>

                        <div class="col-md-3" v-if="label.hasPrintdata">
                        <div class="form-group">
                            <label for="distributor" class="control-label">Druckdatei Download</label>
                            <b-button size="sm" variant="primary" class="form-control form-control-sm" @click="downloadPrintData">Download</b-button>
                        </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import ClassicEditor from './../../../assets/packages/ckeditor5/src/ckeditor';

export default {
    name: "General",

    props:[
        'label'
    ],

    watch: {
        label: {
            handler () {
                this.form.fill(this.label);
                this.form._method = "PUT";
            },
            deep: true
        },
    },

    data() {
        return {
            form: new window.Form({
                id: "",
                name: "",
                description: "",
                distributor: "",
                unit: "",
                type: "",
                logo: [],
                pdf: [],
            }),
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            editMode: false,
        }
    },

    methods:{
        editLabel(){
            this.form
                .post("/labels/"+this.label.id)
                .then(() => {
                    this.$parent.loadLabel();
                    this.editModeOff();
                    this.$swal({
                        icon: "success",
                        title: "Änderungen gespeichert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        editModeOn() {
            this.editMode = true;
        },

        editModeOff() {
            this.form.reset();
            this.form.fill(this.label);
            this.form._method ="PUT";
            this.editMode = false;
        },

        downloadPrintData(){
            this.axios
                .get("/labels/"+this.label.id+"/download/print", {responseType: 'arraybuffer'})
                .then(response => {
                  this.downloadFile(response, this.label.name)
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },

        downloadFile(response, filename){
            var newBlob = new Blob([response.data], {type: 'application/pdf'})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob)
              return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = filename + '.pdf'
            link.click()
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data)
            }, 100)
        },
    },

    created(){
        this.form.fill(this.label);
        this.form._method = "PUT";
    }
    


}
</script>

<style>

</style>